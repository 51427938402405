import React from "react";
import { Typography, useMediaQuery } from "@mui/material";

import LoadingOverlay from "../components/dialog/loadingOverlay";
import OptionsProfitHorizon from "../components/profitHorizon/optionsProfitHorizon";
import OtherOption from "../components/profitHorizon/otherOption";
import TempHeader from "../components/profitHorizon/tempHeader";

import { useAuth0 } from "@auth0/auth0-react";

const ProfitHorizon = () => {
  const isSmScreen = useMediaQuery("(min-width:600px)");

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingOverlay text="Loading..." color="success" />;
  }

  return (
    <div
      style={
        isSmScreen
          ? { padding: 50, paddingLeft: 150, paddingRight: 150, fontSize: 16 }
          : { padding: 2, marginTop: 10 }
      }
    >
      <TempHeader />
      <Typography variant="h4" color="white">
        Compare All Expiries Over Your Selected Time Horizon{" "}
      </Typography>
      <OptionsProfitHorizon />
      <OtherOption />
    </div>
  );
};

export default ProfitHorizon;
