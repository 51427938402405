import React, { PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { Typography, useMediaQuery, Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-6} fill={stroke} fontSize={10} textAnchor="middle">
        {value + "%"}
      </text>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fontSize={12}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const MyLineChart = ({ data, label1, label2 }) => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        margin: "auto",
      }}
    >
      <LineChart
        width={isSmScreen ? 500 : 350}
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 20,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
        <YAxis tickFormatter={(value) => `${value}%`} />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={label1}
          stroke="red"
          label={<CustomizedLabel stroke="red" />}
        />
        <Line
          type="monotone"
          dataKey={label2}
          stroke="white"
          label={<CustomizedLabel stroke="white" />}
        />
      </LineChart>
    </div>
  );
};

const DataChart = ({
  symbol,
  strikePrice,
  ROIs,
  annualROIs,
  expiration_dates,
  maxRORs,
  annualMaxRORs,
}) => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();
  const [selectedBtnText, setSelectedBtnText] = useState("ROI");
  const daysInFuture = useSelector((state) => state.profitHorizon.daysInFuture);
  const fieldsetStyle = {
    width: "60%",
    margin: "auto",
    borderColor: theme.palette.text.third,
    borderRadius: 5,
    marginTop: "3vh",
    paddingTop: 20,
    paddingBottom: 20,
  };
  const [chartData, setChartData] = useState([]);
  const [label1, setLabel1] = useState("ROI");
  const [label2, setLabel2] = useState("Annual. ROI");

  const boxStyle = {
    width: "80vw",
    color: "white",
    "@media (min-width: 600px)": {
      width: "auto", // Set to auto for screens wider than 600px
    },
  };

  useEffect(() => {
    const tempData = [];

    for (let index = 0; index < ROIs.length; index++) {
      const roi = ROIs[index];
      const annualROI = annualROIs[index];
      const maxROR = maxRORs[index];
      const annualMaxROR = annualMaxRORs[index];
      const expiryDate = expiration_dates[index].date;

      switch (selectedBtnText) {
        case "ROI":
          tempData.push({
            name: expiryDate,
            ROI: roi,
            "Annual. ROI": annualROI,
          });
          break;

        case "ROR":
          tempData.push({
            name: expiryDate,
            "Max ROR": maxROR,
            "Annual. Max ROR": annualMaxROR,
          });
          break;

        case "ROI v ROR":
          tempData.push({
            name: expiryDate,
            ROI: roi,
            "Max ROR": maxROR,
          });
          break;

        case "Ann. ROI v ROR":
          tempData.push({
            name: expiryDate,
            "Annual. ROI": annualROI,
            "Annual. Max ROR": annualMaxROR,
          });
          break;

        default:
          break;
      }
    }

    setChartData(tempData);
  }, [ROIs]);

  const handleButtonClick = (text) => {
    setSelectedBtnText(text);
    const tempData = [];

    switch (text) {
      case "ROI":
        for (let index = 0; index < ROIs.length; index++) {
          const roi = ROIs[index];
          const annualROI = annualROIs[index];
          const expiryDate = expiration_dates[index].date;
          tempData.push({
            name: expiryDate,
            ROI: roi,
            "Annual. ROI": annualROI,
          });
        }
        setChartData(tempData);
        setLabel1("ROI");
        setLabel2("Annual. ROI");
        break;

      case "ROR":
        for (let index = 0; index < maxRORs.length; index++) {
          const maxROR = maxRORs[index];
          const annualmaxROR = annualMaxRORs[index];
          const expiryDate = expiration_dates[index].date;
          tempData.push({
            name: expiryDate,
            "Max ROR": maxROR,
            "Annual. Max ROR": annualmaxROR,
          });
        }
        setChartData(tempData);
        setLabel1("Max ROR");
        setLabel2("Annual. Max ROR");
        break;

      case "ROI v ROR":
        for (let index = 0; index < ROIs.length; index++) {
          const roi = ROIs[index];
          const maxROR = maxRORs[index];
          const expiryDate = expiration_dates[index].date;
          tempData.push({
            name: expiryDate,
            ROI: roi,
            "Max ROR": maxROR,
          });
        }
        setChartData(tempData);
        setLabel1("ROI");
        setLabel2("Max ROR");
        break;

      case "Ann. ROI v ROR":
        for (let index = 0; index < annualROIs.length; index++) {
          const annualROI = annualROIs[index];
          const annualMaxROR = annualMaxRORs[index];
          const expiryDate = expiration_dates[index].date;
          tempData.push({
            name: expiryDate,
            "Annual. ROI": annualROI,
            "Annual. Max ROR": annualMaxROR,
          });
        }
        setChartData(tempData);
        setLabel1("Annual. ROI");
        setLabel2("Annual. Max ROR");
        break;
        
      default:
        break;
    }
  };

  return (
    <fieldset style={fieldsetStyle}>
      <Box sx={boxStyle}>
        <Typography color="text.third" marginBottom={6}>
          {`"${symbol}"`} Options Profit {daysInFuture} Day Horizon at $
          {strikePrice} Strike
        </Typography>
      </Box>
      <MyLineChart data={chartData} label1={label1} label2={label2} />
      <Box
        display="flex"
        justifyContent="center"
        mx={2}
        my={2}
        sx={{
          "& .MuiButton-root": {
            backgroundColor: "transparent",
            color: "white",
            border: `1px solid ${theme.palette.text.third}`,
            paddingY: 0,
            paddingX: 2,
          },
          "& .selected": {
            backgroundColor: `${theme.palette.text.third} !important`,
          },
        }}
        gap={2}
      >
        <Button
          className={selectedBtnText === "ROI" ? "selected" : ""}
          onClick={() => handleButtonClick("ROI")}
        >
          ROI
        </Button>
        <Button
          className={selectedBtnText === "ROR" ? "selected" : ""}
          onClick={() => handleButtonClick("ROR")}
        >
          ROR
        </Button>
        <Button
          className={selectedBtnText === "ROI v ROR" ? "selected" : ""}
          onClick={() => handleButtonClick("ROI v ROR")}
        >
          ROI v ROR
        </Button>
        <Button
          className={selectedBtnText === "Ann. ROI v ROR" ? "selected" : ""}
          onClick={() => handleButtonClick("Ann. ROI v ROR")}
        >
          Ann. ROI v ROR
        </Button>
      </Box>
    </fieldset>
  );
};

export default DataChart;
