import * as React from "react";
import { useState } from "react";

import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

import ConversionFreeAccount from "../dialog/conversionFreeAccount";

const DataTable = ({
  sharePrice,
  premiums,
  ROIs,
  annualROIs,
  expiration_dates,
  strikePrice,
  maxReturns,
  maxRisks,
  maxRORs,
  annualMaxRORs,
  symbol,
}) => {
  const theme = useTheme();
  const rowNames = [
    "Symbol",
    "Share Price x 100",
    "Expiry Date",
    "Strike Price",
    "Premium",
    "ROI",
    "Annual. ROI",
    "Max Return",
    "Max Risk",
    "Max ROR",
    "Annual. Max ROR",
  ];

  const [openConversionFreeAccountDialog, setOpenConversionFreeAccountDialog] =
    useState(false);
  const [hideTable, setHideTable] = useState(false);
  const daysInFuture = useSelector((state) => state.profitHorizon.daysInFuture);

  const handleHideTable = () => {
    setHideTable(!hideTable);
  };

  return (
    <>
      <fieldset
        style={{
          width: "60%",
          margin: "auto",
          borderColor: theme.palette.text.third,
          borderRadius: 5,
          marginTop: "3vh",
        }}
      >
        <legend>{`"${symbol}" over the next ${daysInFuture} days`}</legend>
        <Box
          textAlign="center"
          sx={{
            width: "80vw",
            color: "white",
            "@media (min-width: 600px)": {
              width: "auto", // Set to auto for screens wider than 600px
            },
          }}
        >
          <TableContainer>
            <Table aria-label="covered call premiums and returns over table">
              <TableBody
                sx={{
                  "&:last-child td, &:last-child th": { borderBottom: "none" },
                  "& tr td": { color: "white" },
                }}
              >
                <TableRow>
                  <TableCell colSpan={premiums.length + 1} sx={{ padding: 0 }}>
                    <Button
                      sx={{
                        float: "right",
                        color: "text.secondary",
                        paddingY: 0,
                      }}
                      onClick={() => {
                        handleHideTable();
                      }}
                    >
                      {!hideTable ? "Hide" : "Show"} Table
                    </Button>
                  </TableCell>
                </TableRow>
                {!hideTable && (
                  <>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[1]}
                      </TableCell>
                      <TableCell align="center">
                        ${Intl.NumberFormat("en-US").format(sharePrice * 100)}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[3]}
                      </TableCell>
                      <TableCell align="center">${strikePrice}</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[2]}
                      </TableCell>
                      {expiration_dates.map((date, index) => (
                        <TableCell align="center" key={index}>
                          {date.date}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[4]}
                      </TableCell>
                      {premiums.map((item, index) => (
                        <TableCell align="center" key={index}>
                          ${item}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[5]}
                      </TableCell>
                      {ROIs.map((item, index) => (
                        <TableCell align="center" key={index}>
                          {item}%
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[6]}
                      </TableCell>
                      {annualROIs.map((item, index) => (
                        <TableCell align="center" key={index}>
                          {item}%
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={ROIs.length + 1}>
                        <Divider sx={{ backgroundColor: "text.third" }} />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[7]}
                      </TableCell>
                      {maxReturns.map((item, index) => (
                        <TableCell align="center" key={index}>
                          ${item}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[8]}
                      </TableCell>
                      {maxRisks.map((item, index) => (
                        <TableCell align="center" key={index}>
                          ${item}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[9]}
                      </TableCell>
                      {maxRORs.map((item, index) => (
                        <TableCell align="center" key={index}>
                          {item}%
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        {rowNames[10]}
                      </TableCell>
                      {annualMaxRORs.map((item, index) => (
                        <TableCell align="center" key={index}>
                          {item}%
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </fieldset>
      <div
        style={{
          textAlign: "center",
          marginBottom: 30,
        }}
      >
        <ConversionFreeAccount
          open={openConversionFreeAccountDialog}
          onClose={() => setOpenConversionFreeAccountDialog(false)}
        />
      </div>
    </>
  );
};

export default DataTable;
