import React, { useState } from "react";

import {
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const OtherOption = () => {
  const [symbol, setSymbol] = useState("");
  const theme = useTheme();
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const buttonStyle = {
    color: "white",
    background: theme.palette.text.third,
    border: `${theme.palette.text.third} solid 1px`,
    minWidth: 100,
  };

  const handleSymbolChange = (e) => {
    setSymbol(e.target.value);
  };

  return (
    <Box
      textAlign="center"
      sx={{
        // width: "80vw",
        "@media (min-width: 600px)": {
          width: "auto", // Set to auto for screens wider than 600px
        },
      }}
    >
      <Typography variant="h5" color="white" mt={1.5}>
        Enter Another Ticker Symbol
      </Typography>
      <TextField
        value={symbol}
        autoComplete="off"
        onChange={(e) => handleSymbolChange(e)}
        variant="outlined"
        sx={{
          marginTop: 2,
          marginBottom: 2,
          background: "white",
          borderRadius: "10px",
        }}
        inputProps={{
          autoComplete: "new-email",
        }}
        InputProps={{
          style: { color: "black" }, // This will apply the text color to the input
        }}
      />
      <Typography variant="body1" paddingX={isSmScreen ? 40 : 1} marginY={3}>
        We'll keep all your settings the same so you can compare charts on one
        page.
      </Typography>

      <Button sx={buttonStyle}>Calculate</Button>
      <br />
      <Button sx={buttonStyle} style={{ marginTop: 50, minWidth: 200 }}>
        Export to PDF
      </Button>
      <br />
      <Button
        sx={{
          marginY: 2,
          border: `${theme.palette.text.third} solid 1px`,
          color: "white",
        }}
      >
        Clear Results
      </Button>
    </Box>
  );
};

export default OtherOption;
