import React, { useEffect, useState } from "react";

import { Typography, Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";

import DataTable from "./dataTable";
import DataChart from "./dataChart";
import CustomAlert2 from "../customAlert2";

import { getMarketStatus, getExpirations } from "../../actions/stockAction";

const formatDate = (inputDate) => {
  // Split the input date string by "/"
  const parts = inputDate.split("/");

  // Create a new Date object with the parsed parts
  const date = new Date(parts[2], parts[0] - 1, parts[1]);

  // Format the date to 'YYYY-MM-DD' format
  const formattedDate =
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0");

  return formattedDate;
};

const getDaysToExpirationDate = (expiration_date) => {
  let daysToExpirationDate = 1;
  const startDate = new Date(formatDate(expiration_date));
  const current = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(current.getTime() - startDate.getTime());

  // Convert the time difference from milliseconds to days
  daysToExpirationDate = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysToExpirationDate;
};

const CoveredCallPremiumsAndReturnsOverTime = ({ symbol }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedButton, setSelectedButton] = useState("ATM");
  const otm1Percentage = useSelector(
    (state) => state.profitHorizon.otm1Percentage
  );
  const otm2Percentage = useSelector(
    (state) => state.profitHorizon.otm2Percentage
  );
  const expiration_dates = useSelector(
    (state) => state.profitHorizon.expiration_dates
  );
  const sharePrice = useSelector((state) => state.profitHorizon.sharePrice);
  const strikeATMs = useSelector((state) => state.profitHorizon.strikeATMs);
  const strikeOTM5s = useSelector((state) => state.profitHorizon.strikeOTM5s);
  const strikeOTM10s = useSelector((state) => state.profitHorizon.strikeOTM10s);
  const ATMs = useSelector((state) => state.profitHorizon.ATMs);
  const OTM5s = useSelector((state) => state.profitHorizon.OTM5s);
  const OTM10s = useSelector((state) => state.profitHorizon.OTM10s);
  const ROIs = useSelector((state) => state.profitHorizon.ROIs);
  const OTM5ROIs = useSelector((state) => state.profitHorizon.OTM5ROIs);
  const OTM10ROIs = useSelector((state) => state.profitHorizon.OTM10ROIs);
  const annualROIs = useSelector((state) => state.profitHorizon.annualROIs);
  const OTM5annualROIs = useSelector(
    (state) => state.profitHorizon.OTM5annualROIs
  );
  const OTM10annualROIs = useSelector(
    (state) => state.profitHorizon.OTM10annualROIs
  );
  const marketStatus = useSelector((state) => state.stock.marketStatus);
  const [openWarningMarketClosed, setOpenWarningMarketClosed] = useState(true);

  const [selectedSharePrice, setselectedSharePrice] = useState(sharePrice);
  const [selectedStrikePrice, setselectedStrikePrice] = useState(strikeATMs);
  const [selectedPremiums, setselectedPremiums] = useState(ATMs);
  const [selectedROIs, setselectedROIs] = useState(ROIs);
  const [selectedAnnualROIs, setselectedAnnualROIs] = useState(annualROIs);

  const [maxReturns, setMaxReturns] = useState([]);
  const [maxRisks, setMaxRisks] = useState([]);
  const [maxRORs, setMaxRORs] = useState([]);
  const [annualMaxRORs, setAnnualMaxRORs] = useState([]);

  const [maxReturns1, setMaxReturns1] = useState([]);
  const [maxRisks1, setMaxRisks1] = useState([]);
  const [maxRORs1, setMaxRORs1] = useState([]);
  const [annualMaxRORs1, setAnnualMaxRORs1] = useState([]);

  const [maxReturns2, setMaxReturns2] = useState([]);
  const [maxRisks2, setMaxRisks2] = useState([]);
  const [maxRORs2, setMaxRORs2] = useState([]);
  const [annualMaxRORs2, setAnnualMaxRORs2] = useState([]);

  const [maxReturns3, setMaxReturns3] = useState([]);
  const [maxRisks3, setMaxRisks3] = useState([]);
  const [maxRORs3, setMaxRORs3] = useState([]);
  const [annualMaxRORs3, setAnnualMaxRORs3] = useState([]);

  useEffect(() => {
    setselectedSharePrice(sharePrice);
    setselectedStrikePrice(strikeATMs);
    setselectedPremiums(ATMs);
    setselectedROIs(ROIs);
    setselectedAnnualROIs(annualROIs);

    let returns, risks, RORs, annualMaxRORs, daysToExpirationDate;

    // For ATM
    returns = strikeATMs.map((item, index) => {
      return parseFloat(
        (item * 100 - sharePrice * 100 + parseFloat(ATMs[index])).toFixed(0)
      );
    });

    risks = ATMs.map((item, index) => {
      return parseFloat((sharePrice * 100 - item).toFixed(0));
    });

    RORs = returns.map((item, index) => {
      return parseFloat(((item / risks[index]) * 100).toFixed(2));
    });

    // Convert the time difference from milliseconds to days

    annualMaxRORs = RORs.map((item, index) => {
      return parseFloat(
        (
          (Math.pow(
            1 + item / 100,
            365 / getDaysToExpirationDate(expiration_dates[index].date)
          ) -
            1) *
          100
        ).toFixed(2)
      );
    });

    setMaxReturns1(returns);
    setMaxRisks1(risks);
    setMaxRORs1(RORs);
    setAnnualMaxRORs1(annualMaxRORs);

    setMaxReturns(returns);
    setMaxRisks(risks);
    setMaxRORs(RORs);
    setAnnualMaxRORs(annualMaxRORs);

    // For ATM5
    returns = strikeOTM5s.map((item, index) => {
      return parseFloat(
        (item * 100 - sharePrice * 100 + parseFloat(OTM5s[index])).toFixed(0)
      );
    });

    risks = OTM5s.map((item, index) => {
      return parseFloat((sharePrice * 100 - item).toFixed(0));
    });

    RORs = returns.map((item, index) => {
      return parseFloat(((item / risks[index]) * 100).toFixed(2));
    });

    // Convert the time difference from milliseconds to days

    annualMaxRORs = RORs.map((item, index) => {
      return parseFloat(
        (
          (Math.pow(
            1 + item / 100,
            365 / getDaysToExpirationDate(expiration_dates[index].date)
          ) -
            1) *
          100
        ).toFixed(2)
      );
    });

    setMaxReturns2(returns);
    setMaxRisks2(risks);
    setMaxRORs2(RORs);
    setAnnualMaxRORs2(annualMaxRORs);

    // For ATM10
    returns = strikeOTM10s.map((item, index) => {
      return parseFloat(
        (item * 100 - sharePrice * 100 + parseFloat(OTM10s[index])).toFixed(0)
      );
    });

    risks = OTM10s.map((item, index) => {
      return parseFloat((sharePrice * 100 - item).toFixed(0));
    });

    RORs = returns.map((item, index) => {
      return parseFloat(((item / risks[index]) * 100).toFixed(2));
    });

    // Convert the time difference from milliseconds to days
    annualMaxRORs = RORs.map((item, index) => {
      return parseFloat(
        (
          (Math.pow(
            1 + item / 100,
            365 / getDaysToExpirationDate(expiration_dates[index].date)
          ) -
            1) *
          100
        ).toFixed(2)
      );
    });

    setMaxReturns3(returns);
    setMaxRisks3(risks);
    setMaxRORs3(RORs);
    setAnnualMaxRORs3(annualMaxRORs);
  }, [sharePrice, strikeATMs, ATMs, ROIs, annualROIs]);

  useEffect(() => {
    if (marketStatus === "closed") {
      setOpenWarningMarketClosed(true);
    }
  }, [marketStatus]);

  useEffect(() => {
    dispatch(getExpirations());
    dispatch(getMarketStatus());
  }, [dispatch]);

  const handleButtonClick = (name) => {
    setSelectedButton(name);

    switch (name) {
      case "ATM":
        setselectedSharePrice(sharePrice);
        setselectedStrikePrice(strikeATMs);
        setselectedPremiums(ATMs);
        setselectedROIs(ROIs);
        setselectedAnnualROIs(annualROIs);
        setMaxReturns(maxReturns1);
        setMaxRisks(maxRisks1);
        setMaxRORs(maxRORs1);
        setAnnualMaxRORs(annualMaxRORs1);

        break;

      case otm1Percentage + "%OTM":
        setselectedStrikePrice(strikeOTM5s);
        setselectedPremiums(OTM5s);
        setselectedROIs(OTM5ROIs);
        setselectedAnnualROIs(OTM5annualROIs);
        setMaxReturns(maxReturns2);
        setMaxRisks(maxRisks2);
        setMaxRORs(maxRORs2);
        setAnnualMaxRORs(annualMaxRORs2);

        break;

      case otm2Percentage + "%OTM":
        setselectedStrikePrice(strikeOTM10s);
        setselectedPremiums(OTM10s);
        setselectedROIs(OTM10ROIs);
        setselectedAnnualROIs(OTM10annualROIs);
        setMaxReturns(maxReturns3);
        setMaxRisks(maxRisks3);
        setMaxRORs(maxRORs3);
        setAnnualMaxRORs(annualMaxRORs3);

        break;
    }
  };

  return (
    <div>
      <Typography
        variant="h4"
        color="text.third"
        textAlign="center"
        mt={5}
        mb={4}
      >
        Covered Call Premiums and % Returns over Time
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        mx={2}
        my={2}
        sx={{
          "& .MuiButton-root": {
            backgroundColor: "transparent",
            color: "white",
            border: `1px solid ${theme.palette.text.third}`,
            paddingY: 0,
            paddingX: 2,
          },
          "& .selected": {
            backgroundColor: `${theme.palette.text.third} !important`,
          },
        }}
        gap={2}
      >
        <Button
          className={selectedButton === "ATM" ? "selected" : ""}
          onClick={() => handleButtonClick("ATM")}
        >
          ATM
        </Button>
        <Button
          className={
            selectedButton === otm1Percentage + "%OTM" ? "selected" : ""
          }
          onClick={() => handleButtonClick(otm1Percentage + "%OTM")}
        >
          {otm1Percentage}% <br /> OTM
        </Button>
        <Button
          className={
            selectedButton === otm2Percentage + "%OTM" ? "selected" : ""
          }
          onClick={() => handleButtonClick(otm2Percentage + "%OTM")}
        >
          {otm2Percentage}% <br /> OTM
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <CustomAlert2
          openState={openWarningMarketClosed}
          severity="warning"
          text="Options premium data may have errors outside of trading hours of major US exchanges. If you see $0 premium value, 
            try again once markets open. We are working with our data provider to resolve this issue."
          autoHideDuration={null}
          onClose={() => {
            setOpenWarningMarketClosed(false);
          }}
        />
      </Box>

      <DataChart
        symbol={symbol}
        strikePrice={selectedStrikePrice[0]}
        ROIs={selectedROIs}
        annualROIs={selectedAnnualROIs}
        expiration_dates={expiration_dates}
        maxRORs={maxRORs}
        annualMaxRORs={annualMaxRORs}
      />
      <DataTable
        sharePrice={selectedSharePrice}
        expiration_dates={expiration_dates}
        premiums={selectedPremiums}
        ROIs={selectedROIs}
        annualROIs={selectedAnnualROIs}
        strikePrice={selectedStrikePrice[0]}
        maxReturns={maxReturns}
        maxRisks={maxRisks}
        maxRORs={maxRORs}
        annualMaxRORs={annualMaxRORs}
        symbol={symbol}
      />
    </div>
  );
};

export default CoveredCallPremiumsAndReturnsOverTime;
